class Componentes {
	constructor(){
		this.componentes = {};
	}

	define(elemento, Compositor){
		let els = document.querySelectorAll(elemento);

		if(els){
			this.componentes[elemento] = {
				seletor: elemento,
				compositor: Compositor,
				list: []
			};

			for(let el in els){
				this.componentes[elemento].list.push(new Compositor(els[el]));
			}
		}
	}
}

let elementosCustom = new Componentes();