(function(){
	let hasOwl = document.querySelector('.owl-carousel');

	if(hasOwl){
		let css = document.createElement('link');

		css.rel = 'stylesheet';
		css.type = 'text/css';
		css.href = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css';

		document.head.appendChild(css);

		let js = document.createElement('script');
		
		js.src = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js';

		document.body.appendChild(js);

		js.addEventListener('load', function(){
			$('.owl-carousel').owlCarousel({
				autoplay: true,
				nav: false,
				responsive : {
					0 : {
						items : 1
					},
					500 : {
						items : 2
					},
					700 : {
						items : 4
					},
					900 : {
						items: 5
					},
					1200: {
						items: 6
					}
				}
			});
		});
	}
})();