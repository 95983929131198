(function(){
	let lightboxItens = document.querySelector('[data-lightbox]');

	if(lightboxItens){
		let css = document.createElement('link');

		css.rel = 'stylesheet';
		css.href = 'https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.3/css/lightbox.min.css';
		css.type = 'text/css';

		document.head.appendChild(css);

		let js = document.createElement('script');

		js.defer = true;
		js.src = 'https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.3/js/lightbox.min.js';

		document.body.appendChild(js);
	}
})();