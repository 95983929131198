(function () {
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				entry.target.style.setProperty('background-image', `url(${JSON.stringify(entry.target.dataset.lazyBg)})`);
				observer.unobserve(entry.target);
			}
		})
	}, {
		rootMargin: '200px 200px 200px 200px'
	});
	const ElList = document.querySelectorAll('[data-lazy-bg]');

	ElList.forEach($el => observer.observe($el));
})();