(function() {

    class LazyImage extends HTMLElement {
        static get observedAttributes() {
            return [
                'src',
                'srcset',
                'width',
                'height',
                'alt'
            ];
        }

        constructor() {
            super();
            this.shadow = this.attachShadow({mode: 'open'});
            this.render();
        }

        render() {
            let style    = document.createElement('style');
            let img      = document.createElement('img');
            let observer = new IntersectionObserver(entries => {
                if(entries[0].isIntersecting) {
                    img.src = img.dataset.src;
                    img.removeAttribute('data-src');
                    observer.disconnect();
                }
            }, {rootMargin: '200px 200px 200px 200px'});

            img.src = this.placeholder;
            img.alt = this.alt || this.filename;
            img.dataset.src = this.src;

            if(!this.width || !this.height) {
                img.addEventListener('load', e => {
                    let {naturalWidth, naturalHeight} = e.target;
                    
                    this.setAttribute('width', naturalWidth);
                    this.setAttribute('height', naturalHeight);

                    this.style.setProperty('--width', naturalWidth)
                    this.style.setProperty('--height', naturalHeight)
                })
            }

            style.innerHTML = `
            :host{
                display: inline-block;
                position: relative;
                overflow: hidden;
                vertical-align:middle;
                width: 100%;
                max-width: calc(var(--width, ${this.width}) * 1px);
            }

            :host::before{
                content:'';
                display: block;
                padding-bottom: calc(
                    (var(--height, ${this.height}) / var(--width, ${this.width})) * 100%
                );
            }

            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
            `;

            this.shadow.innerHTML = '';
            this.shadow.append(style, img);

            observer.observe(img);
        }

        get filename () {
            let filename = this.src.split('/');

            filename = filename[filename.length - 1];
            filename = filename.split('.')[0];

            return filename;
        }

        get placeholder() {
            return `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='${this.width}' height='${this.height}' viewBox='0 0 ${this.width} ${this.height}'><rect width='${this.width}' height='${this.width}' fill='%23ddd'/></svg>`
        }

        get src () {
            return this.getAttribute('src')
        }

        get srcset () {
            return this.getAttribute('srcset')
        }

        get width () {
            return this.getAttribute('width')
        }

        get height () {
            return this.getAttribute('height')
        }

        get alt () {
            return this.getAttribute('alt')
        }

        get sizes () {
            return this.getAttribute('sizes')
        }
    }

    customElements.define('lazy-image', LazyImage);

})();