$('[data-toggle="menu"]').click(function(event) {
	var topo = document.querySelector('.topo');
	var bgTopo = document.createElement('div');

	$(bgTopo).addClass('bg-topo');

	$(bgTopo).click(function(){
		$(topo).removeClass('open');
		$('body, html').removeClass('lock-scroll');

		$(this).fadeOut(600, function(){
			$(this).remove();
		});
	});

	$(topo).addClass('open');
	$('body, html').addClass('lock-scroll');

	topo.appendChild(bgTopo);

	$(bgTopo).fadeIn(600);
});